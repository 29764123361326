import React from 'react';

const ContactPage = () => {
    return (
        <div className="bg-gray-100 text-gray-800 px-6 md:px-20 py-10 mt-36">
            <h1 className="text-3xl font-bold mb-6 text-center">Privacy Policy</h1>
            <div className="space-y-6">
                <p>
                    At OTR Solutions, we are committed to protecting your privacy and ensuring the confidentiality of your personal information. This privacy policy applies to our website, <a href="https://otrsolutions.site/" className="text-blue-600 underline">https://otrsolutions.site/</a>, and outlines the types of information we collect, how we use it, and the steps we take to protect it.
                </p>

                <h2 className="text-xl font-semibold">Information Collection</h2>
                <p>
                    We collect information from you when you visit our website or fill out a form on our site. This information may include your name, email address, phone number, and any other information you provide. We may also collect information about your browsing activity on our website, such as the pages you visit and the links you click on.
                </p>
                <p>
                    In addition, we may collect information from cookies and other tracking technologies, such as IP addresses, to personalize your experience on our website and analyze site usage.
                </p>

                <h2 className="text-xl font-semibold">Use of Information</h2>
                <p>
                    We use the information we collect from you to improve the services we provide and to better understand our customers’ needs. No mobile information will be shared with third parties/affiliates for marketing/promotional purposes. All other categories exclude text messaging originator opt-in data and consent; this information will not be shared with any third parties. We do not sell or share your personal information with third parties.
                </p>

                <h2 className="text-xl font-semibold">SMS Disclosure</h2>
                <p>
                    SMS opt-in or phone numbers for the purpose of SMS are not being shared.
                </p>

                <h2 className="text-xl font-semibold">Your Individual Rights</h2>
                <p>
                    You may have certain rights relating to your Personal Data based on applicable local data protection laws. Depending on the applicable laws, these rights may include the right to:
                </p>
                <ul className="list-disc pl-6 space-y-2">
                    <li>Request and receive copies of your Personal Data that we hold;</li>
                    <li>Request additional information about how we process your Personal Data;</li>
                    <li>Correct inaccurate or incomplete Personal Data (taking into account our use of it);</li>
                    <li>Request deletion of your Personal Data;</li>
                    <li>Restrict or object to our processing of your Personal Data. Where we process Personal Data for direct marketing purposes (either by us or third parties), you may not have to provide a specific reason for such objection;</li>
                    <li>Require us (if possible) to transfer your Personal Data to another controller (i.e., data portability);</li>
                    <li>Restrict certain disclosures of your Personal Data to third parties;</li>
                    <li>
                        Not be subject to a decision based solely on automated processing, including profiling, which produces legal effects; and
                    </li>
                    <li>
                        Withdraw your consent to the processing of your Personal Data (to the extent we base processing on consent and not on another lawful basis).
                    </li>
                </ul>

                <h2 className="text-xl font-semibold">Security Measures</h2>
                <p>
                    We take steps to protect your personal information from unauthorized access, use, or disclosure. This includes using encryption technology when transmitting sensitive information and regularly monitoring our systems for potential security vulnerabilities.
                </p>

                <h2 className="text-xl font-semibold">California Consumer Privacy Act Disclosures</h2>
                <p>
                    The categories of personal information we collect, including name, email address, phone number, and browsing activity.
                </p>
                <p>
                    The sources from which we collect personal information, such as directly from you or from cookies and tracking technologies.
                </p>
                <p>
                    The purposes for which we use personal information, such as to improve our services and understand our customers’ needs. We do not sell or share your personal information with third parties.
                </p>

                <h2 className="text-xl font-semibold">Changes to This Policy</h2>
                <p>
                    We reserve the right to update this privacy policy at any time. If we make any changes, we will post the updated policy on our website and notify you by email.
                </p>

                <h2 className="text-xl font-semibold">Contact Us</h2>
                <p>
                    If you have any questions or concerns about our privacy policy, please contact us at <a href="mailto:Info@otrsolutions.site" className="text-blue-600 underline">Info@otrsolutions.site</a> or call us at <a href="tel:+13022760440" className="text-blue-600 underline">(302) 276-0440</a>.
                </p>
                <p>
                    Address: 1942 Broadway #314C, Boulder CO 80302.
                </p>
            </div>
        </div>
    );
};

export default ContactPage;

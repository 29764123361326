import React from 'react'

import aboutGroupPhoto from "../../assets/Images/about-group-photo.webp";  // Foreground (cars) image



const HeroTruck = () => {
    return (
        <div>

            <div className="bg-cover bg-center mt-28" style={{ backgroundImage: `url(${aboutGroupPhoto})` }}>
                <div className={`bg-black opacity-55`}>
                    <div className={`w-full px-2 pt-4 text-white md:px-[38%]`}>
                        <h1 className={`text-1xl text-[#ffdd00] pb-20 font-bold text-center pt-10`}>Truck Dispatch | Contact US</h1>
                        <h1 className={`text-[#ffdd00] text-2xl md:text-5xl font-bold text-center pb-[70%]`}>Contact US</h1>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroTruck
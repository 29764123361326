import React from 'react'

import pricesVanTruck from "../../assets/Images/prices-van-truck.webp";  // Foreground (cars) image


const HeroTruck = () => {
  return (
    <div>
      <div className="bg-cover bg-center mt-28" style={{ backgroundImage: `url(${pricesVanTruck})` }}>
            <div className={`bg-black h-[50vh] opacity-65`}>
                <div className={`w-full px-2 pt-4 text-white md:px-[38%]`}>
                    <h1 className={`text-1xl text-[#ffdd00] pb-20 font-bold text-center pt-5`}>Truck Dispatch | Prices</h1>
                    <h1 className={`text-[#ffdd00] text-2xl md:text-5xl font-bold text-center pb-2`}>Our Service Pricing</h1>
                    <p className="pb-5 text-center font-bold">We are transparent and straightforward in our pricing model. No hidden fees, no additional expenses</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default HeroTruck
import React from 'react'
  // Foreground (cars) image
import HomeDryService from "../../assets/Images/howWorkTruck.png";  // Foreground (cars) image
  // Foreground (cars) image
// import Data from '../../CompanyInfo';

const HowItWorks = () => {
    return (
        <div className=''>


            <div className={`px-7 py-20 bg-black }`}>
                <div className='md:px-[23%] md:grid md:grid-rows-1 justify-center items-center md:grid-cols-2 gap-5'>
                    <div className='md:order-1'>
                    {/* <h1 className='text-2xl md:text-2xl text-[#ffdd00] font-bold'>THE MOST INNOVATIVE & DRIVER FRIENDLY DISPATCH SERVICES</h1> */}
                    <h1 className='text-2xl md:text-6xl pb-5 text-[#ffdd00] font-bold'>How it Works?</h1>
                    <p className={`pb-5 text-xl text-white`}>We provide dispatching services truckers trust in because we are professionals with years of experience in the brokerage and deep knowledge of ins and outs. We know the specifics of the equipment along with federal and local regulations. Our dispatcher company constantly monitors freight rates, load boards, brokers, and shippers to spot the best deals for our clientage.</p>
                    </div>
                    <div className='md:order-2'>
                        <img src={HomeDryService} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HowItWorks
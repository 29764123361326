import React from 'react';
import HomeHeroTruck from "../../assets/Images/HomeHeroTruck.jpg";  // Background image
import Main2 from "../../assets/Images/main_2.jpg";  // Overlay image
import { Link } from 'react-router-dom';

const HeroTruck = () => {
    return (
        <div className="relative h-[100vh] -mt-8 md:mt-36">
            {/* Main Background Image */}
            <div className="bg-cover bg-center mt-36" style={{ backgroundImage: `url(${HomeHeroTruck})` }}>
                {/* Overlay Image */}
                <div
                    className="absolute inset-0 bg-cover bg-center opacity-50"
                    style={{ backgroundImage: `url(${Main2})` }}
                ></div>

                {/* Content on top of the images */}
                <div className="bg-black h-[100vh] bg-opacity-65 flex justify-center relative">
                    <div className="w-full px-2 pt-4 text-white md:px-[5%]">
                        <h1 className="text-1xl text-[#ffdd00] pb-24 font-bold text-center pt-5">
                            Truck Dispatch | Home
                        </h1>
                        <h1 className="text-[#ffdd00] text-6xl md:text-9xl font-bold text-center md:text-left pb-6">
                            OTR Solutions
                        </h1>
                        <p className="pb-10  text-center md:text-left text-2xl  md:text-4xl font-bol">
                            Dedicated Dispatch Service For Owner Operators and Carriers
                        </p>
                        <div className='text-center md:text-left'>
                        <button className="px-6 py-3 rounded-xl text-3xl text-white  font-bold bg-[#11265D]">
                            <Link to={"/contact-us"}>Contact US</Link>
                        </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeroTruck;

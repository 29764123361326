import React from 'react'
// import Data from '../../CompanyInfo'
import TuckAndTraler from '../../assets/Images/TuckAndTraler.png'; // Foreground (cars) image


const PricingPackages = () => {
  return (
    // <div className="bg-cover bg-bottom h-[70vh]" style={{ backgroundImage: `url(${TuckAndTraler})`  }}>
    <div className="bg-contain px-8 bg-bottom bg-no-repeat h" style={{ backgroundImage: `url(${TuckAndTraler})` }}>

      <div className='py-10 md:py-20 md:grid md:grid-cols-2 md:grid-rows-1 md:gap-10 md:px-[24%] '>
        <div  className={`border-2 border-[#0762d9] shadow-2xl bg-white`}>
          <h1 className={`py-5  text-center text-2xl font-bold bg-[#0762d9]`}>Your Authority</h1>
          <p className='pt-4 px-4 text-center'>Price for our truck dispatch services starts from 5% gross revenue and up depending on your equipment:&nbsp;</p>
          <ul className='pt-4 px-10 font-bold list-disc'>
            <li>Box truck -10%</li>
            <li>Hotshot -5%</li>
            <li>Flatbed/StepDeck -5%</li>
            <li>Dry Van/Reefer/Power Only -5%</li>
          </ul>
          <div className='justify-center   pt-32 pb-6 flex'>
            <button className={`px-14 py-2 rounded-full font-bold mx-auto bg-[#0762d9]`}>Subscribe</button>
          </div>
        </div>


        <div  className={`border-2 mt-20 md:mt-0 border-[#0762d9] shadow-2xl  bg-white`}>
          <h1 className={`py-5  text-center text-2xl font-bold bg-[#0762d9]`}>Our Authority</h1>
          <p className='pt-4 px-4 text-center'>Price for freight services under our MC authority starts from 15% gross revenue and up. You will get:&nbsp;</p>
          <ul className='pt-4 px-10 font-bold list-disc'>
            <li>Our MC Authority</li>
            <li>Insurance & Safety</li>
            <li>24/7 Dispatch Support</li>
            <li>Document Management and other Benefits.</li>
          </ul>
          <div className='justify-center   pt-32 pb-6 flex'>
            <button className={`px-14 py-2 rounded-full font-bold mx-auto bg-[#0762d9]`}>Subscribe</button>
          </div>
        </div>

      </div>
    </div>
  )
}

export default PricingPackages
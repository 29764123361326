import React from 'react'

const ContentSection = () => {
  return (
    <div className='px-4 md:px-[20%]'>
        <h1 className='pb-4 pt-2 text-3xl'>About Us</h1>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;OTR Solutions  Company was founded in 2017 in New York, NY; furthermore, its founders have 15-year working experience in Logistics and Finance markets of the USA, Canada and Europe. The aim of the company is to make the drivers’ life easier when they are on the road and help them solve recurring issues that are connected with cargo transportation, paperwork and its processing, and getting paid on time.</p>
        <h1 className='py-4 text-3xl'>About team</h1>

        <p className='pb-6'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  Today our company has over 50 experienced Dispatchers that are split into mini-teams. Every mini-team has its Team Leader who provides coordination, control and development of employees. Such a working model allows them to cooperate efficiently within the team, providing quick response to personal requests of our clients and keeping top quality <span className='text-blue-700 underline'><a href="/about">dispatching service</a></span></p>
        <p className='pb-6'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  Our Dispatchers take a special training course, pass exams and only then are allowed to get down to work under the supervision of our experienced mentors. A lot of our dispatchers have significant working experience in the Logistics industry of the USA.</p>
        <p className='pb-6'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  Our teams are located in different parts of the world, such as the USA, Canada, Europe and Ukraine. It allows us to be mobile and sustainable, share our experience, and optimize costs as well as improve our working performance within the team. We are especially proud of our Ukrainian team that shows one of the best results in performance and quality service of our customers being located on the territory of Ukraine.</p>
        <p className='pb-6'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  Having great experience in Trucking, we have faced the issues that drivers are lied to or not told everything as it is. It is about the price for cargo, the term of payment, specific requirements to hauling, etc. That’s why we initially made a decision that we’ll give them the most accurate and truthful information and will become their attorneys, defending their interests in front of the cargo owners, and also in front of the other participants of the market.</p>
        <p className='pb-6'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  Our clients value it a lot when they work with us as well as our individual approach to each client.</p>


        <h1 className='pb-4 pt-8 text-3xl'>About Our Clients</h1>

        <p className='pb-6'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  Our clients are drivers / owners of trucks or small / medium trucking companies in the USA.</p>
        <p className='pb-6'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  We help our clients find better loads, negotiate about the best cost, ease the pain with paperwork and provide good customer support.</p>
        <p className='pb-6'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  It allows truck owner operators to concentrate on the road and not get distracted by dealing with recurring issues and questions that need to be solved.</p>
        <p className='pb-6'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  For Owner Operators we assign one of our dispatchers who usually provides service for 4-5 trucks, Team Leader, whom he can always reach out on any recurring matter and a Customer Service Specialist who monitors the quality of service and satisfaction with our service.</p>
        <p className='pb-6'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  For companies with 30 + trucks we attach a particular team of Dispatchers that work exclusively with the trucks of one client and become his Outsource team working remotely. It helps us save large budget and company source on recruiting new Dispatchers and training them, coordinating and controlling their work, giving them room in the office and benefits package. The client pays a fixed fee for each truck that is serviced by our team, which is sustainably lower than keeping a full-time Dispatcher in the company.</p>
        <p className='pb-6'>&nbsp;</p>
        <p className='pb-6'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  During our work, we’ve established close relations with other companies that are working in this business and we are proud of being trustworthy. These companies provide a wide range of services such as factoring, insurance and truck selling. We are always open to new cooperation and try to build relations in every possible way where every party will benefit.</p>
    </div>
  )
}

export default ContentSection
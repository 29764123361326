import React from 'react'
import Navbar from '../components/Navbar'
import HeroTruck from '../components/HomeComp/HeroTruck'
import Vision from '../components/HomeComp/Vision'
import HelpCenter from '../components/HomeComp/HelpCenter'
import PricingPackages from '../components/HomeComp/PricingPackages '
import HowItWorks from '../components/HomeComp/HowItWorks'
import Footer from '../components/Footer'

const Home = () => {
  return (
    <div>
      <Navbar />
      <HeroTruck />
      <Vision />
      <HelpCenter />
      <PricingPackages />
      <HowItWorks />
      <Footer />
    </div>
  )
}

export default Home

import React from 'react'
  // Foreground (cars) image
import HomeDryService from "../../assets/Images/dry-van-dispatch.jpg";  // Foreground (cars) image
  // Foreground (cars) image
// import Data from '../../CompanyInfo';

const Vision = () => {
    return (
        <div className=''>


            <div className={`px-7 py-20 bg-black }`}>
                <div className='md:px-[23%] md:grid md:grid-rows-1 justify-center items-center md:grid-cols-2 gap-5'>
                    <div className='md:order-2'>
                    <h1 className='text-2xl md:text-2xl text-[#ffdd00] font-bold'>THE MOST INNOVATIVE & DRIVER FRIENDLY DISPATCH SERVICES</h1>
                    <h1 className='text-white text-5xl font-bold py-3'>Our Vision</h1>
                    <p className={`pb-5 text-xl text-white`}>We provide dispatching services truckers trust in because we are professionals with years of experience in the brokerage and deep knowledge of ins and outs</p>
                    </div>
                    <div className='md:order-1'>
                        <img src={HomeDryService} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Vision
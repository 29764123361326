import React from 'react'
import Help1 from "../../assets/Images/help1.svg";  // Background image
import Help2 from "../../assets/Images/help2.svg";  // Background image
import Help3 from "../../assets/Images/help3.svg";  // Background image

const HelpCenter = () => {
  return (
    <div>
      <div className='my-28'>
        <h1 className='text-center text-2xl md:text-7xl px-10 font-bold  pb-10'>Our Expert & Trusted Consultants Help Clients</h1>
        <p className='text-center text-3xl'>Best Logistics careers for the future</p>

        <div className='md:grid grid-rows-1  grid-cols-3 px-[10%] mx-auto gap-14 font-[Merriweather-Sans]'>
            <div className='px-8 py-10 my-10 rounded-3xl shadow-2xl shadow-black  text-left'> 
                <img className='size-[20%] mb-5 '   src={Help1} alt="" />
                <h2 className='text-2xl md:text-4xl font-bold text-[#0762d9]'>Dedicated Dispatcher</h2>
                <h4 className='text-xl pt-2'>You will have an assigned dedicated dispatcher to ensure you get the service you deserve</h4>
            </div>


            <div className='px-8 py-10 my-10 rounded-3xl shadow-2xl shadow-black  text-left'> 
            <img className='size-[20%] mb-5' src={Help3} alt="" />

                <h2 className='text-2xl md:text-4xl font-bold text-[#0762d9]'>Process Paperwork</h2>
                <h4 className='text-xl pt-2'>Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. </h4>
            </div>


            <div className='px-8 py-10 my-10 rounded-3xl shadow-2xl shadow-black  text-left'> 
            <img className='size-[20%] mb-5' src={Help2} alt="" />

                <h2 className='text-2xl md:text-4xl font-bold text-[#0762d9]'>Experience/Know ledge</h2>
                <h4 className='text-xl pt-2'>Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. </h4>
            </div>
        
        </div>
      </div>
    </div>
  )
}

export default HelpCenter
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        Name: '',
        Email: '',
        Phone: '',
        Message: '',
        Consent: false,
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        alert("Thanks for submitting!!! your Team contact you soon!!!")
        
        console.log('Form submitted:', formData);
        // Add your form submission logic here (e.g., API call)
    };

    return (
        <div className="py-16 px-8 bg-gray-50">
            <form
                onSubmit={handleSubmit}
                className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-xl"
            >
                <h1 className="text-3xl font-bold text-center mb-6 text-gray-800">
                    Get in Touch
                </h1>

                {/* Name */}
                <div className="mb-4">
                    <label className="block text-gray-600 font-medium mb-2">Name</label>
                    <input
                        type="text"
                        name="Name"
                        placeholder="Your Name"
                        value={formData.Name}
                        onChange={handleChange}
                        required
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
                    />
                </div>

                {/* Email */}
                <div className="mb-4">
                    <label className="block text-gray-600 font-medium mb-2">Email</label>
                    <input
                        type="email"
                        name="Email"
                        placeholder="Your Email"
                        value={formData.Email}
                        onChange={handleChange}
                        required
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
                    />
                </div>

                {/* Phone */}
                <div className="mb-4">
                    <label className="block text-gray-600 font-medium mb-2">Phone</label>
                    <input
                        type="tel"
                        name="Phone"
                        placeholder="Your Phone Number"
                        value={formData.Phone}
                        onChange={handleChange}
                        required
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
                    />
                </div>

                {/* Message */}
                <div className="mb-6">
                    <label className="block text-gray-600 font-medium mb-2">Message</label>
                    <textarea
                        name="Message"
                        placeholder="Your Message"
                        value={formData.Message}
                        onChange={handleChange}
                        rows="4"
                        required
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
                    ></textarea>
                </div>

                {/* Consent Checkbox */}
                <div className="mb-6 flex items-start">
                    <input
                        type="checkbox"
                        name="Consent"
                        
                        checked={formData.Consent}
                        onChange={handleChange}
                        required
                        className="mt-1 mr-2 size-10"
                    />
                    
                    <label className="text-gray-600">
                        By checking this box, you agree to receive text messages from OTR Solution
                        LLC. You can reply STOP to opt out at any time. (<Link to={'/privacy-policy'} className='text-blue-800 hover:underline font-bold'>Privacy Policy</Link>)
                    </label>
                </div>

                {/* Submit Button */}
                <div className="text-center">
                    <button
                        type="submit"
                        className="w-full px-6 py-3 bg-yellow-500 text-white font-bold text-lg rounded-lg hover:bg-yellow-600 transition"
                    >
                        Send
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ContactForm;

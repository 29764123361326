import React from 'react'
import Navbar from '../components/Navbar'

import PricingPackages from '../components/HomeComp/PricingPackages '

import Footer from '../components/Footer'
import HeroTruck from '../components/PricesComponents/HeroTruck'

const Pricing = () => {
  return (
    <div>
      <Navbar />
      <HeroTruck />
      <PricingPackages />
      <Footer />
    </div>
  )
}

export default Pricing

import React from 'react'
import Navbar from '../components/Navbar'
import HeroTruck from '../components/ContactComp/HeroTruck'
import ContactForm from '../components/ContactComp/CarrierForm'
import Footer from '../components/Footer'

const Contact = () => {
  return (
    <div>
      <Navbar />
      <HeroTruck />
      <ContactForm />
      <Footer />
    </div>
  )
}

export default Contact

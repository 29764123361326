import React from 'react'
import Navbar from '../components/Navbar'
import HeroTruck from '../components/AboutComp/HeroTruck'
import ContentSection from '../components/AboutComp/ContentSection'
import Footer from '../components/Footer'

const About = () => {
  return (
    <div>
      <Navbar />
      <HeroTruck />
      <ContentSection />
      <Footer />
    </div>
  )
}

export default About
